import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CtaButton from "../../components/CtaButton";
// import InnerContainter from "../../components/InnerContainer";

const HalfCtaContainer = styled.div`
  /* background-color: ${props => props.theme.colors.lightPeach};
  padding: 0 1rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 6rem;
  } */
`;

const FlexContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    /* justify-content: space-between; */
  }
  .boxname0 {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      display: flex;
      justify-content: flex-end;
      /* padding: 7.7m 10rem 9.1rem 3rem; */
    }
    .section-box {
      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        margin: 7.7rem 10rem 9.1rem auto;
      }
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin: 7.7rem 16rem 9.1rem auto;
      }
    }
  }
  .boxname1 {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      display: flex;
      justify-content: flex-start;
      /* padding: 7.7rem 3rem 9.1rem 10rem; */
    }
    .section-box {
      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        margin: 7.7rem auto 9.1rem 10rem;
      }
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin: 7.7rem auto 9.1rem 16rem;
      }
    }
  }
`;

const CtaSection = styled.div`
  width: 100%;
`;

const CtaSectionBox = styled.div`
  padding: 2rem;
  max-width: 350px;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 430px;
  }
`;

const CtaSectionTitle = styled.h2`
  font-family: ${props => props.theme.fonts.medium};
  font-weight: 500;
  font-size: 4rem;
  line-height: 38px;
  margin-bottom: 4rem;
  color: ${props => props.theme.colors.lightPeach};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 4.8rem;
    line-height: 60px;
    margin-bottom: 2rem;
  }
`;

const CtaSectionSubtitle = styled.h4`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 2.3rem;
  line-height: 35px;
  margin-bottom: 2.5rem;
  color: ${props => props.theme.colors.lightPeach};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.6rem;
    line-height: 39px;
    min-height: 95px;
    margin-bottom: 1rem;
  }
`;

const CtaSectionText = styled.p`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 1.8rem;
  line-height: 29px;
  margin-bottom: 4rem;
  color: ${props => props.theme.colors.lightPeach};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 0rem;
    min-height: 205px;
  }
`;

const HalfWidthTextCta = props => {
  // console.log(props);
  return (
    <HalfCtaContainer>
      <FlexContainer>
        {props.sections.map((section, i) => (
          <CtaSection
            key={section.title}
            css={css`
              background-color: ${section.backgroundColor};
            `}
            className={`boxname${i}`}
          >
            <CtaSectionBox className="section-box">
              <CtaSectionTitle>{section.title}</CtaSectionTitle>
              <CtaSectionSubtitle>{section.subtitle}</CtaSectionSubtitle>
              <CtaSectionText>{section.paragraph}</CtaSectionText>
              <CtaButton
                type={section.buttonType}
                color={section.buttonColor}
                link={section.buttonLink}
              />
            </CtaSectionBox>
          </CtaSection>
        ))}
      </FlexContainer>
    </HalfCtaContainer>
  );
};

export default HalfWidthTextCta;
