import React from "react";
import styled from "@emotion/styled";
import Image from "../../components/image";
import PageLink from "../../components/PageLink";

const PhotoContainer = styled.div`
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 43rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 60rem;
  }
`;

const LayoutImage = ({ data }) => {
  return (
    <PhotoContainer>
      {data?.imageLinkUrl ? (
        <PageLink to={data?.imageLinkUrl?.url}>
          <Image image={data.image} />
        </PageLink>
      ) : (
        <Image image={data.image} />
      )}
    </PhotoContainer>
  );
};

export default LayoutImage;
