import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useStaticQuery, graphql } from "gatsby";
import Descriptor from "../../components/Descriptor/index";
import CtaButton from "../../components/CtaButton";
import InsightCardSmall from "../../components/InsightCardSmall";
import InnerContainer from "../../components/InnerContainer";

const LatestInsightContainer = styled.div`
  background-color: #f4edeb;
  padding: 5rem 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 5rem 6rem;
  }
`;

const InsightFlexContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
`;

const LatestInsightsContent = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 99rem;
  }
`;

const DescriptorBox = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 17rem;
    margin-right: 10rem;
  }
`;

const InsightTitle = styled.h2`
  font-family: ${props => props.theme.fonts.medium};
  font-weight: 500;
  font-size: 4rem;
  line-height: 50px;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 6.5rem;
    line-height: 80px;
    margin-top: 0rem;
  }
`;

const InsightSubtitle = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 1.8rem;
  line-height: 29px;
  color: ${props => props.theme.colors.offBlack};
  /* margin-bottom: 5rem; */

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 93%;
    font-size: 2.5rem;
    line-height: 39px;
  }
`;

const InsightList = styled.div`
  margin-top: 5rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 6rem;
  }
`;

// const InsightLink = styled(Link)`
//   font-family: ${props => props.theme.fonts.regular};
//   font-size: 1.8rem;
//   line-height: 25px;
//   letter-spacing: 2.7px;
//   text-transform: uppercase;
//   text-decoration: underline;
//   color: #f4edeb;
// `;

function createSubtitle(props) {
  return { __html: props.subtitle };
}

const LatestInsights = props => {
  // console.log(props);
  // destructure the data from the query and rename the "nodes" field as "posts" for semantic clarity
  const {
    allWpPost: { nodes: posts },
  } = useStaticQuery(latestInsightsQuery);
  return (
    <LatestInsightContainer>
      <InnerContainer>
        <InsightFlexContainer>
          <DescriptorBox>
            <Descriptor
              title={props.descriptor}
              color={props.descriptorColor}
              alignment={props.underlineAlignment}
            />
          </DescriptorBox>
          <LatestInsightsContent>
            <InsightTitle
              css={css`
                color: ${props.titleColor};
              `}
            >
              {props.title}
            </InsightTitle>
            <InsightSubtitle dangerouslySetInnerHTML={createSubtitle(props)} />
            <InsightList>
              {posts.map(post => (
                <InsightCardSmall key={post.databaseId} insight={post} />
              ))}
            </InsightList>
            <CtaButton
              type={props.buttonType}
              color={props.buttonColor}
              link={props.buttonLink}
            />
          </LatestInsightsContent>
        </InsightFlexContainer>
      </InnerContainer>
    </LatestInsightContainer>
  );
};

const latestInsightsQuery = graphql`
  {
    allWpPost(sort: { fields: date, order: DESC }, limit: 2) {
      nodes {
        databaseId
        title
        date(formatString: "MMM D, YYYY")
        uri
        status
        tags {
          nodes {
            name
            databaseId
          }
        }
        categories {
          nodes {
            name
            databaseId
          }
        }
        terms {
          nodes {
            name
            databaseId
          }
        }
        Insight {
          fieldGroupName
          cardThumbnailDesktop {
            databaseId
            altText
            localFile {
              extension
              publicURL
              relativePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          cardThumbnailMobile {
            databaseId
            altText
            localFile {
              extension
              publicURL
              relativePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          featuredImageDesktop {
            databaseId
            altText
            localFile {
              extension
              publicURL
              relativePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          featuredImageMobile {
            databaseId
            altText
            localFile {
              extension
              publicURL
              relativePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          cardCtaText
        }
      }
    }
  }
`;
export default LatestInsights;
