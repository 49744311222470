import React from "react";
import styled from "@emotion/styled";
import LayoutTextCta from "./LayoutTextCta";
import LayoutImage from "./LayoutImage";
import InnerContainer from "../../components/InnerContainer";

const TEXTCTA_LAYOUT =
  "Page_Pagebuilder_Layouts_HalfWidthCtaImage_Layouts_TextCta";
const IMAGE_LAYOUT = "Page_Pagebuilder_Layouts_HalfWidthCtaImage_Layouts_Image";

const HalfWidthImageComponent = styled.div`
  width: 100%;
  padding: 3.25rem 1.5rem 4.35rem;
  background-color: ${props => props.theme.colors.offWhite};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    justify-content: space-between;
    padding: 11rem 6rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const HalfWidthCtaImage = ({ layouts }) => {
  const renderLayouts = (fieldGroupName, layoutData, loopIndex) => {
    switch (fieldGroupName) {
      case TEXTCTA_LAYOUT:
        return <LayoutTextCta key={loopIndex} data={layoutData} />;
      case IMAGE_LAYOUT:
        return <LayoutImage key={loopIndex} data={layoutData} />;
      default:
        return null;
    }
  };
  return (
    <HalfWidthImageComponent>
      <InnerContainer>
        <FlexContainer>
          {layouts.map((layout, i) =>
            renderLayouts(layout.fieldGroupName, layout, i)
          )}
        </FlexContainer>
      </InnerContainer>
    </HalfWidthImageComponent>
  );
};

export default HalfWidthCtaImage;
