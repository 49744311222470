import React from "react";
import styled from "@emotion/styled";
import Descriptor from "../../components/Descriptor/index";
import CtaButton from "../../components/CtaButton";
import CaseStudyCard from "../../components/CaseStudyCard";
import InnerContainer from "../../components/InnerContainer";

const FeaturedContainer = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 5rem 1.5rem 2rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 8.5rem 6rem;
  }
`;

const DescriptorContainer = styled.div`
  margin-bottom: 3.5rem;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 7.5rem;
  }
`;

const CtaContainer = styled.div`
  margin-top: 5.5rem;
`;

const FeaturedCaseStudy = props => {
  // console.log(props);
  return (
    <FeaturedContainer>
      <InnerContainer>
        <DescriptorContainer>
          <Descriptor
            title={props.descriptor}
            color={props.descriptorColor}
            alignment={props.underlineAlignment}
          />
        </DescriptorContainer>
        <CaseStudyCard caseStudy={props.featuredCaseStudy} />
        <CtaContainer>
          <CtaButton
            type={props.buttonType}
            color={props.buttonColor}
            link={props.buttonLink}
          />
        </CtaContainer>
      </InnerContainer>
    </FeaturedContainer>
  );
};

export default FeaturedCaseStudy;
