import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../../components/Descriptor/index";
import CtaButton from "../../components/CtaButton";

const HalfWidthInfo = styled.div`
  margin-bottom: 5.25rem;
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    /* max-width: 43rem;
    margin-right: 4rem; */
    max-width: 43rem;
    margin-right: 4rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* width: 47.03125%;
    margin-top: 1.25rem; */
    /* max-width: 60rem;
    margin-right: 6rem; */
    max-width: 60rem;
    margin-right: 6rem;
  }
`;

const HalfWidthTitle = styled.h2`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 4rem;
  line-height: 50px;
  margin-top: 4rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 6.5rem;
    line-height: 80px;
    margin-top: 8rem;
  }
`;

const HalfWidthSubtitle = styled.p`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 1.8rem;
  line-height: 29px;
  margin-top: 2.5rem;
  margin-bottom: 4rem;
  color: ${props => props.theme.colors.offBlack};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.5rem;
    line-height: 39px;
    margin-top: 4rem;
    margin-bottom: 8.5rem;
  }
`;

const LayoutTextCta = ({ data }) => {
  return (
    <HalfWidthInfo>
      <Descriptor
        title={data.descriptor}
        color={data.descriptorColor}
        alignment={data.underlineAlignment}
      />
      <HalfWidthTitle
        css={css`
          color: ${data.titleColor};
        `}
      >
        {data.title}
      </HalfWidthTitle>
      <HalfWidthSubtitle>{data.subtitle}</HalfWidthSubtitle>
      <CtaButton
        type={data.buttonType}
        color={data.buttonColor}
        link={data.buttonLink}
      />
    </HalfWidthInfo>
  );
};

export default LayoutTextCta;
